import React from 'react'

const LotInfo = ({ name, charge, fee, status }) => {
  return (
    <div className='p-12 bg-secondary rounded-xl mb-20'>
      <h4 className='mb-4'>Lot Data:</h4>
      <div className='flex justify-between items-center'>
        <span>Lot name:</span>
        <span>{name}</span>
      </div>
      <div className='flex justify-between items-center'>
        <span>Charge amount:</span>
        <span>${charge}</span>
      </div>
      <div className='flex justify-between items-center'>
        <span>Fee Collected:</span>
        <span>${fee}</span>
      </div>
      <div className='flex justify-between items-center'>
        <span>Status:</span>
        <span>
          {(status == 1) ? (
            <>Active</>
          ) : (
            <>Inactive</>
          )}
        </span>
      </div>
    </div>
  )
}

export default LotInfo