import React, { useState, useEffect } from 'react'
import { Alert, AlertTitle } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';
import LotInfo from './partials/LotInfo';
import ValCodes from './partials/ValCodes';

const LotDisplay = () => {
  const settings = useSettings();
  const token = settings.userToken;
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [lot, setLot] = useState()
  const [codes, setCodes] = useState()
  const [addVal, setAddVal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newCode, setNewCode] = useState()

  /**
   * 
   */
  useEffect(() => {
    document.title = 'Lot Management | ValParx Admin'
    
    if (token && !lot) {
      getData()
    }
  }, []);

  /**
   * 
   */
  const getData = () => {
    setLot(null)

    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "getlotdata", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 200) {
          setLot(result.lot)
          setCodes(result.codes)
        } 
      })
      .catch(error => console.log('error', error));
  }

  /**
   * 
   */
  const toggleAddValidationCode = () => {
    setAddVal(addVal => !addVal)
  }

  /**
   * 
   * @returns 
   */
  const handleValSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)

    if (!newCode) {
      setErrorMessage('A new code is required.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("lot", id);
    formdata.append("code", newCode);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "addvalcode", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        setErrorMessage(result.message)

        if (result.code == 400) {
          setIsSubmitting(false)
          setShowError(true)
        } else {
          setAddVal(false)
          setNewCode('')
          setShowSuccess(true)
          setIsSubmitting(false)
          getData()
        }
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div className='min-h-screen flex items-start justify-center pt-[63px]'>
      <Nav /> 

      {lot ? (
        <div className='w-full'>
          <div className="max-w-4xl mx-auto w-full pt-20">
            <div className='flex justify-between items-center mb-12'>
              <h2 className='mb-0'>{lot.name}</h2>
              <Link to={"/lots/edit/"+id} className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center max-w-160 m-0">
                Edit Lot
                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='ml-2' />
              </Link>
            </div>
            
            <LotInfo name={lot.name} charge={lot.charge_amount} fee={lot.fee} status={lot.active} />

            <div className='flex justify-between items-center mb-12'>
              <h3 className='mb-0'>Validation Codes</h3>
              <a onClick={toggleAddValidationCode} className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center max-w-160 m-0 cursor-pointer">
                Add Code
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" className='ml-2' />
              </a>
            </div>

            {showError ? (
              <Alert severity="error" variant="filled" className='mb-12' onClose={() => {setShowError(false)}}>
                <AlertTitle className='capitalize'>Error</AlertTitle>
                {errorMessage}
              </Alert>
            ) : (<></>)}

            {showSuccess ? (
              <Alert severity="success" variant="filled" className='mb-12' onClose={() => {setShowSuccess(false)}}>
                <AlertTitle className='capitalize'>Success</AlertTitle>
                {errorMessage}
              </Alert>
            ) : (<></>)}

            {addVal && (
              <form onSubmit={handleValSubmit}>
                <div className='mx-auto p-8 lg:p-12 border border-solid dark:border-secondary border-primary rounded-3xl my-10 '>
                  <div className='mb-10 w-full'>
                    <h4 className='tex-base'>Enter validation code *</h4>
                    <input
                      id="name"
                      type="text"
                      value={newCode}
                      onChange={(event) => setNewCode(event.target.value)}
                      onBlur={(event) => setNewCode(event.target.value)}
                      className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                    />
                  </div>

                  {isSubmitting ? (
                    <div className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center">
                      <FontAwesomeIcon icon='fa-spinner fa-solid' className="text-white fa-spin" />
                    </div>
                  ) : (
                    <input type="submit" value="Submit" className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center cursor-pointer" />
                  )}  
                </div>
              </form>
            )}

            <ValCodes codes={codes} />
          </div>
        </div>
      ) : (
        <div className='text-blueGray-500 items-center flex flex-col text-center justify-center w-full h-screen'>
          <p className='text-4xl font-bold mb-6'>Loading lot data...</p>
          <div><FontAwesomeIcon icon="fa-solid fa-spinner" className='text-5xl ml-2 fa-spin' /></div>
        </div>
      )}
      
    </div>
  )
}

export default LotDisplay