import React, { useState, useEffect } from 'react'

import { API_URL } from '../Constants';
import Loading from './Loading';

const TVDisplay = ({ id }) => {
  const [data, setData] = useState(null)
  const minutes = 0.5;
  
  useEffect(() => {
    if (!data) {
      getData()
    }

    // Set up the interval to fetch data every * minute(s).
    const intervalId = setInterval(getData, ((1000 * 60) * minutes)); 

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const getData = () => {
    var formdata = new FormData();
    formdata.append("id", id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "tvlots", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code === 200) {
          setData(result.transactions)
        }
      })
      .catch(error => console.log('error', error));
  }

  const statusDisplay = (status) => {
    if (parseInt(status) === 1) {
      return (
        <div className='text-right text-green-500'>Open</div>
      )
    }

    if (parseInt(status) === 0) {
      return (
        <div className='text-right'>Closed</div>
      )
    }

    if (parseInt(status) === 2) {
      return (
        <div className='text-right text-yellow-500'>Paid</div>
      )
    }

    if (parseInt(status) === 3) {
      return (
        <div className='text-right text-red-500'>Ready For Pickup</div>
      )
    }
  }

  return (
    <div className=''>
      {data ? (
        <div>
          <div className='flex justify-between items-center px-10 w-full fixed bg-secondary'>
            <div className='text-left uppercase font-bold p-2 w-1/6'>Tag</div>
            <div className='text-center uppercase font-bold p-2 w-1/3'>Name</div>
            <div className='text-center uppercase font-bold p-2 w-1/3'>Phone</div>
            <div className='text-right uppercase font-bold p-2 w-1/6'>Status</div>
          </div>

          <div className='min-h-screen flex-col flex items-center justify-start bg-primary py-4 px-2 sm:px-6 lg:px-8'>
            <ul className='w-full pt-10'>
              {data.map((transaction, index) => (
                <li key={index} className='flex justify-between items-center w-full bg-gray-50 bg-opacity-80'>
                  <div className='text-left p-2 w-1/6'>{transaction.key_tag}</div>
                  <div className='text-center p-2 w-1/3'>{transaction.customer_name}</div>
                  <div className='text-center p-2 w-1/3'>{transaction.customer_phone}</div>
                  <div className='text-right p-2 w-1/6'>{statusDisplay(transaction.status)}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <Loading name="Data" />
      )}
    </div>    
  ) 
}

export default TVDisplay