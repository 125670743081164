import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

export const Nav = () => {
   
   const logout = () => {
      localStorage.removeItem('authKey')
      //removeCookie('authKey', { path: '/' });
      window.location.reload();
   }

   return (
      <nav className="absolute top-0 left-0 w-full z-10 bg-primary lg:flex-row lg:flex-nowrap lg:justify-start flex items-center py-1 px-4 border-b border-solid border-white">
         <div className="w-full mx-aut0 items-center flex justify-between lg:flex-nowrap flex-wrap lg:px-6 px-4">
            <h1 className='mb-2 text-white'>ValParx Admin</h1>

            <div className="items-center w-full lg:flex lg:w-auto flex-grow duration-300 transition-all ease-in-out lg:h-auto-important hidden justify-end">
                <Link to={"/"} className="text-blueGray-800 lg:text-white text-sm uppercase inline-block font-semibold my-3">
                    Home
                </Link>
                <span className='text-white mx-4'>|</span>
                <Link to={"/users"} className="text-blueGray-800 lg:text-white text-sm uppercase inline-block font-semibold my-3">
                    Users
                </Link>
                <span className='text-white mx-4'>|</span>
                <Link to={"/lots"} className="text-blueGray-800 lg:text-white text-sm uppercase inline-block font-semibold my-3">
                    Lots
                </Link>
               <button onClick={logout} className="text-gray-900 ml-14 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center">
                  <FontAwesomeIcon icon="fa-solid fa-power-off" className='mr-2' />
                  Logout
               </button>
            </div>
         </div>
      </nav>
   )
}
