import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Nav } from './partials/Nav';

const NotFound = () => {

  useEffect(() => {
    document.title = 'Page Not Found | ValParx Admin'
  }, []);

  return (
    <div className="w-full min-h-screen relative flex ct-docs-disable-sidebar-content overflow-x-hidden">
      <div className="relative bg-white w-full">
        <Nav />
        <div className="relative pt-14">
          <div className='text-center min-h-screen -mt-14 flex mx-auto max-w-2xl flex-col justify-center items-center'>
            <h1 className='text-center text-2xl lg:text-8xl  mb-10'>Not Found.</h1>
            <FontAwesomeIcon
              icon={'fa-solid fa-ban'}
              className={'text-red-500 text-5xl lg:text-9xl mb-10'}
            />
            <h3 className='lg:text-xl text-md '>Sorry, but the page you are looking for is not found. Please using the navigation in the header to navigate the site.</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound