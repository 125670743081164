import React from 'react'
import { useSettings } from '../context/SettingsContext'

const Settings = () => {
  const settings = useSettings();

  return (
    <div className='min-h-screen flex-col flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8'>
      <div className="max-w-md w-full space-y-8 text-white">
        <p>Settings</p>
      </div>
    </div>
  )
}

export default Settings