import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';

const Home = () => {
  const settings = useSettings();
  //const [cookies, setCookie, removeCookie] = useCookies(['authKey']);

  useEffect(() => {
    document.title = 'Home | ValParx Admin'
  }, []);

  const logout = () => {
    //removeCookie('authKey', { path: '/' });
    localStorage.removeItem('authKey')
    window.location.href = '/';
  }

  return (
    <div className='min-h-screen flex-col flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <Nav />
      <div className="max-w-xl w-full space-y-8 text-center">
        <h1>Welcome to ValParx Admin Panel</h1>
        <p>What would you like to do?</p>

        <div className='max-w-sm mx-auto'>
          <Link to={"/lots"} className="group relative w-full flex justify-center py-2 px-4 border font-bold my-2 border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition">
            Lot Management
          </Link>

          <Link to={"/users"} className="group relative w-full flex justify-center py-2 px-4 border font-bold my-2 border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition">
            User Management
          </Link>

          <Link to={"/reports"} className="group relative w-full flex justify-center py-2 px-4 border font-bold my-2 border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition">
            Reports
          </Link>

          <button onClick={logout} className="group relative w-full flex justify-center items-center py-2 px-4 border mt-12 font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition">
            <FontAwesomeIcon icon="fa-solid fa-power-off" className='mr-2' />
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default Home