import React, { useEffect, useState } from 'react'
import { Alert, InputLabel, AlertTitle, MenuItem, FormHelperText, Select, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';

const UserEdit = () => {
  const settings = useSettings();
  const token = settings.userToken;
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [level, setLevel] = useState(2)
  const [showSuccess, setShowSuccess] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    document.title = (id ? 'Editing User' : 'Add User') + ' | ValParx Admin'

    if (id) {
      var myHeaders = new Headers();
      myHeaders.append("vp-session-token", token);

      var formdata = new FormData();
      formdata.append("id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "/getuser", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          if (result.code == 200) {
            setEmail(result.user.email)
            setName(result.user.name)
            setLevel(result.user.level)
          } else {
            setErrorMessage(result.message)
            setShowError(true)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, []);

  const handleName = (event) => {
    setName(event.target.value)
  }

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleLevel = (event) => {
    setLevel(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)

    if (!name) {
      setErrorMessage('The name is a required field. Please check the name you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (!email) {
      setErrorMessage('The email address is a required field. Please check the name you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (id) {
      if (changePassword) {
        if (!password) {
          setErrorMessage('The password is a required field. Please check the name you provided and try again.')
          setShowError(true)
          setIsSubmitting(false)
          return;
        }
      }
    } else {
      if (!password) {
        setErrorMessage('The password is a required field. Please check the name you provided and try again.')
        setShowError(true)
        setIsSubmitting(false)
        return;
      }
    }
    
    if (id) {
      submitUpdate()
    } else {
      submitInput()
    }
  }

  const submitUpdate = () => {
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    if (changePassword) {
      formdata.append("password", password);
    }
    formdata.append("level", "2");
    formdata.append("id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/updateuser", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        setErrorMessage(result.message)

        if (result.code == 400) {
          setShowError(true)
        } else {
          setShowSuccess(true)
        }

        setIsSubmitting(false)
      })
      .catch(error => {
        //console.log('error', error)
        setErrorMessage('There was an error submitting the data. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
      });
  }

  const submitInput = () => {
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("level", level);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/adduser", requestOptions)
      .then(response => response.json())
      .then(result => {
        setErrorMessage(result.message)

        if (result.code == 400) {
          setShowError(true)
        } else {
          setShowSuccess(true)
        }

        setIsSubmitting(false)
      })
      .catch(error => {
        setErrorMessage('There was an error submitting the data. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
      });
  }

  return (
    <div className='min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <Nav /> 
      <div className="max-w-2xl w-full space-y-8 pt-20">
        <h2 className='mb-12'>{id ? 'Editing User' : 'Add User'}</h2>

        <div className='w-90 max-w-2xl mx-auto'>
          {showError ? (
            <Alert severity="error" variant="filled" className='mb-12' onClose={() => {setShowError(false)}}>
              <AlertTitle className='capitalize'>Error</AlertTitle>
              {errorMessage}
            </Alert>
          ) : (<></>)}

          {showSuccess ? (
            <Alert severity="success" variant="filled" className='mb-12' onClose={() => {setShowSuccess(false)}}>
              <AlertTitle className='capitalize'>Success</AlertTitle>
              {errorMessage}
            </Alert>
          ) : (<></>)}

          <form onSubmit={handleSubmit}>
            <div className='mx-auto p-8 lg:p-12 border border-solid dark:border-secondary border-primary rounded-3xl my-10 '>
              <div className='mb-10 w-full'>
                <h4 className='tex-xl'>Name *</h4>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={handleName}
                  onBlur={handleName}
                  className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                />
              </div>

              <div className='mb-10 w-full'>
                <h4 className='tex-xl'>Email Address *</h4>
                <input
                  id="name"
                  type="text"
                  value={email}
                  onChange={handleEmail}
                  onBlur={handleEmail}
                  className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                />
              </div>

              {id ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={changePassword}
                        onChange={(event) => setChangePassword(event.target.checked)}
                        color="primary"
                      />
                    }
                    label="Change Password?"
                  />
                  <div className={`mb-10 w-full ${changePassword ? '' : 'hidden'}`}>
                    <h4 className='tex-xl'>Password *</h4>
                    <input
                      id="name"
                      type="text"
                      value={password}
                      onChange={handlePassword}
                      onBlur={handlePassword}
                      className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                    />
                  </div>
                </div>
              ) : (
                <div className='mb-10 w-full'>
                  <h4 className='tex-xl'>Password *</h4>
                  <input
                    id="name"
                    type="text"
                    value={password}
                    onChange={handlePassword}
                    onBlur={handlePassword}
                    className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                  />
                </div>
              )}

              <div className='mt-10 md:flex justify-between items-center'>
                <div className='md:w-2/3 md:mb-0 mb-5'>
                  <h4 className='tex-xl'>Level</h4>
                </div>
                <div className='md:1/3 '>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={level}
                      label="interval"
                      onChange={handleLevel}
                    >
                      <MenuItem value={1}>Manager</MenuItem>
                      <MenuItem value={2}>Runner</MenuItem>
                    </Select>
                    <FormHelperText>Minutes</FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>

            {isSubmitting ? (
              <div className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center">
                <FontAwesomeIcon icon='fa-spinner fa-solid' className="text-white fa-spin" />
              </div>
            ) : (
              <input type="submit" value="Submit" className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center cursor-pointer" />
            )}  
          </form>
        </div>
      </div>
    </div>
  )
}

export default UserEdit