import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';

const Lots = () => {
  const settings = useSettings();
  const [lots, setLots] = useState()

  useEffect(() => {
    document.title = 'Lot Management | ValParx Admin'
    let token = settings.userToken;
    
    if (token && !lots) {
      var myHeaders = new Headers();
      myHeaders.append("vp-session-token", token);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(API_URL + "/getlots", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)

          if (result.code == 400) {
            setLots([])
          } else {
            setLots(result.lots)
          }
        })
        .catch(error => {
          console.log('error', error)
        });
    }

  }, []);

  return (
    <div className='min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <Nav /> 
      <div className="max-w-5xl w-full space-y-8 pt-20">
        <div className='flex justify-between items-center mb-12'>
          <h2 className='mb-0'>Lot Management</h2>
          <Link to={"/lots/add/"} className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center max-w-160 m-0">
            Add Lot
            <FontAwesomeIcon icon="fa-solid fa-circle-plus" className='ml-2' />
          </Link>
        </div>
        
        {lots ? (
          (lots.length > 0 ? (
            <ul id='sites' className={`w-full max-w-2xl mx-auto`}>
              {lots.map((lot, index) => (
                <li key={index} className="mb-2">
                  <Link to={"/lots/"+lot.id} className=" uppercase py-1 font-bold flex justify-between items-center bg-gray-100 rounded-lg p-2">
                    <span className='overflow-x-clip text-gray-900'>{lot.name}</span>
                    <FontAwesomeIcon icon="fa-arrow-right fa-solid" className='mr-2 text-sm text-accent ' />
                  </Link>
                </li>
              ))}
            </ul>
           ) : (
            <p className='text-center font-medium'>No active lots found. <br></br>You can start adding some with the button above.</p>
           ))
        ) : (
          <p className='text-blueGray-500 items-center flex text-center justify-center'>
            <span>Loading Lots...</span>
            <FontAwesomeIcon icon="fa-solid fa-spinner" className='text-sm ml-2 fa-spin' />
          </p>
        )}
      </div>
    </div>
  )
}

export default Lots