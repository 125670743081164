import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';

const LotEdit = () => {
  const settings = useSettings();
  const token = settings.userToken;
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('')
  const [showSuccess, setShowSuccess] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('')
  const [fee, setFee] = useState('')

  useEffect(() => {
    document.title = (id ? 'Editing Lot' : 'Add Lot') + ' | ValParx Admin'

    if (id) {
      var myHeaders = new Headers();
      myHeaders.append("vp-session-token", token);

      var formdata = new FormData();
      formdata.append("id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "/getlots", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          if (result.code == 200) {
            setName(result.lot.name)
            setFee(result.lot.fee)
            setChargeAmount(result.lot.charge_amount)
          } else {
            setErrorMessage(result.message)
            setShowError(true)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)

    if (!name) {
      setErrorMessage('The name is a required field. Please check the name you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (!fee) {
      setErrorMessage('The fee amount is a required field. Please check the value you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }

    if (!chargeAmount) {
      setErrorMessage('The charge amount is a required field. Please check the value you provided and try again.')
      setShowError(true)
      setIsSubmitting(false)
      return;
    }
    
    submitInput()
  }

  const submitInput = () => {
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("fee", fee);
    formdata.append("charge_amount", chargeAmount);
    if (id) {
      formdata.append("id", id);
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "/editlot", requestOptions)
      .then(response => response.json())
      .then(result => {
        setErrorMessage(result.message)

        if (result.code == 400) {
          setShowError(true)
        } else {
          setShowSuccess(true)
        }

        setIsSubmitting(false)
      })
      .catch(error => {
        setErrorMessage('There was an error submitting the data. Please try again.')
        setShowError(true)
        setIsSubmitting(false)
      });
  }

  return (
    <div className='min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <Nav /> 
      <div className="max-w-2xl w-full space-y-8 pt-20">
        <h2 className='mb-12'>{id ? 'Editing Lot' : 'Add Lot'}</h2>

        <div className='w-90 max-w-2xl mx-auto'>
          {showError ? (
            <Alert severity="error" variant="filled" className='mb-12' onClose={() => {setShowError(false)}}>
              <AlertTitle className='capitalize'>Error</AlertTitle>
              {errorMessage}
            </Alert>
          ) : (<></>)}

          {showSuccess ? (
            <Alert severity="success" variant="filled" className='mb-12' onClose={() => {setShowSuccess(false)}}>
              <AlertTitle className='capitalize'>Success</AlertTitle>
              {errorMessage}
            </Alert>
          ) : (<></>)}

          <form onSubmit={handleSubmit}>
            <div className='mx-auto p-8 lg:p-12 border border-solid dark:border-secondary border-primary rounded-3xl my-10 '>
              <div className='mb-10 w-full'>
                <h4 className='tex-xl'>Lot Name *</h4>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onBlur={(event) => setName(event.target.value)}
                  className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                />
              </div>

              <div className='mb-10 w-full'>
                <h4 className='tex-xl'>Charge Amount *</h4>
                <input
                  id="name"
                  type="text"
                  value={chargeAmount}
                  onChange={(event) => setChargeAmount(event.target.value)}
                  onBlur={(event) => setChargeAmount(event.target.value)}
                  className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                />
              </div>

              <div className='mb-10 w-full'>
                <h4 className='tex-xl'>Fee Amount *</h4>
                <input
                  id="name"
                  type="text"
                  value={fee}
                  onChange={(event) => setFee(event.target.value)}
                  onBlur={(event) => setFee(event.target.value)}
                  className="text-2xl bg-transparent border border-solid border-accent w-full p-1"
                />
              </div>
            </div>

            {isSubmitting ? (
              <div className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center">
                <FontAwesomeIcon icon='fa-spinner fa-solid' className="text-white fa-spin" />
              </div>
            ) : (
              <input type="submit" value="Submit" className="group relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center cursor-pointer" />
            )}  
          </form>
        </div>
      </div>
    </div>
  )
}

export default LotEdit