import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { API_URL } from './Constants';
import TVDisplay from './partials/TVDisplay';
import Loading from './partials/Loading';

const TV = () => {
  const { id } = useParams();

  const [lots, setLots] = useState()

  useEffect(() => {
    document.title = 'TV | ValParx'

    if (!id) {
      var myHeaders = new Headers();

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(API_URL + "/tvlots", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          if (result.code === 400) {
            setLots([])
          } else {
            setLots(result.lots)
          }
        })
        .catch(error => {
          console.log('error', error)
        });
    }
  }, []);

  return(
    <>
      {id ? (
        <TVDisplay id={id} />
      ) : (
        <div className='min-h-screen flex-col flex items-center justify-center bg-primary py-12 px-4 sm:px-6 lg:px-8'>
          <div className="max-w-md w-full mx-auto">
            <h1 className='mt-6 text-center text-3xl font-bold text-white'>
              Choose lot to display:
            </h1>

            {lots ? (
              (lots.length > 0 ? (
                <ul id='sites' className={`w-full max-w-2xl mx-auto`}>
                  {lots.map((lot, index) => (
                    <li key={index} className="my-2">
                      <Link to={"/tv/"+lot.id} className=" uppercase py-1 font-bold flex justify-between items-center bg-gray-100 rounded-lg p-3">
                        <span className='overflow-x-clip text-gray-900'>{lot.name}</span>
                        <FontAwesomeIcon icon="fa-arrow-right fa-solid" className='mr-2 text-sm text-accent ' />
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-center font-medium'>No active lots found. <br></br>Make sure you have at least one lot active that can collect transactions.</p>
              ))
            ) : (
              <Loading name="Lots" />
            )}
          </div>
        </div> 
      )}
    </> 
  )
}

export default TV