import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = ({ name }) => {
  return(
    <p className='text-blueGray-500 items-center flex text-center justify-center'>
      <span>Loading {name}...</span>
      <FontAwesomeIcon icon="fa-solid fa-spinner" className='text-sm ml-2 fa-spin' />
    </p>
  )
}

export default Loading;