import React, {useEffect, useState} from 'react'

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';

const Reports = () => {
  return (
    <div className='min-h-screen flex-col flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <Nav />
      <div className="max-w-xl w-full space-y-8 text-center">
        <h1 className='mb-14'>Reports</h1>
        <p>Coming soon.</p>
      </div>
    </div>
  )
}

export default Reports;