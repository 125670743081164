import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faEllipsisVertical, faNewspaper, faUserCircle, faPaintBrush, faCirclePlus, faSpinner, faBars, faPowerOff, faArrowRight, faAngleRight, faCheck, faTimes, faBan } from '@fortawesome/free-solid-svg-icons';

import { SettingsProvider } from './context/SettingsContext';

import Home from './components/Home';
import Settings from './components/Settings';
import Users from './components/Users';
import NotFound from './components/NotFound';
import {API_URL, LOGIN_URL} from './components/Constants';
import UserEdit from './components/UserEdit';
import Lots from './components/Lots';
import LotEdit from './components/LotEdit';
import LotDisplay from './components/LotDisplay';
import TV from './components/TV';
import Reports from './components/Reports';
import { red } from '@mui/material/colors';

library.add(fas, faEllipsisVertical, faNewspaper, faUserCircle, faPaintBrush, faCirclePlus, faSpinner, faBars, faPowerOff, faArrowRight, faAngleRight, faCheck, faTimes, faBan )

/* ------------------------------------------ */

function App() {
  const [authStatus, setAuthStatus] = useState(null);
  const [settings, setSettings] = useState(null);

  /**
   * useEffect 
   */
  useEffect(() => {
    const itemStr = localStorage.getItem('authKey');
    document.title = 'Login | ValParx Admin';

    if (itemStr) {
      const item = JSON.parse(itemStr);
      checkToken(item.value, false)
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const getkey = urlParams.get('key');

      if (getkey) {
        checkToken(getkey, true)
      } else {
        //console.log('here')
        setAuthStatus(false);
      }
    }
  }, []);

  /**
   * 
   * @param {str}  key 
   * @param {bool} storeAndRedirect 
   */
  const checkToken = (key, storeAndRedirect) => {
    var myHeaders = new Headers();
    //myHeaders.append("vp-authorization", AUTH_TOKEN);

    var formdata = new FormData();
    formdata.append("key", key);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + '/gettoken', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          //need to store the user_id in localstorage for usage
          const settings = {
            //apiUrl: API_URL,
            userToken: data.token,
            //authToken: AUTH_TOKEN
          };
          setSettings(settings);
          
          if (storeAndRedirect) {
            const expirationDate = new Date();
            const item = {
              value: key,
              expires: expirationDate.getTime() + (1000 * 60 * 24 * 7), // Adding 7 days to the current date (makes no difference. Expires is handled at by the API)
            };
            localStorage.setItem('authKey', JSON.stringify(item));

            window.location.href = '/';
          } else {
            setAuthStatus(true);
          }
        } else {
          localStorage.removeItem('authKey'); //remove any key from local storage
          setAuthStatus(false);
        }
      });
  }

  return (
    <SettingsProvider value={settings}>
      <Router>
        {authStatus ? (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path='/users/add' element={<UserEdit />} />
            <Route exact path="/users/edit/:id" element={<UserEdit />} />
            <Route exact path="/lots" element={<Lots />} />
            <Route exact path="/lots/add" element={<LotEdit />} />
            <Route exact path="/lots/edit/:id" element={<LotEdit />} />
            <Route exact path="/lots/:id" element={<LotDisplay />} />
            <Route exact path="/tv" element={<TV />} />
            <Route exact path="/tv/:id" element={<TV />} />
            <Route exact path='/reports' element={<Reports />} />

            {/* Fallback 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/" element={<RedirectToLogin />} />
            <Route exact path="/tv" element={<TV />} />
            <Route exact path="/tv/:id" element={<TV />} />
          </Routes>
        )}
      </Router>
    </SettingsProvider>
  );
}

function RedirectToLogin() {
  return (
    <div className='min-h-screen flex-col flex items-center justify-center bg-primary py-12 px-4 sm:px-6 lg:px-8'>
      <div className="max-w-md w-full space-y-8">
        <h1 className='mt-6 text-center text-3xl font-bold text-white'>
          Please login below:
        </h1>
        <a href={LOGIN_URL} className="group relative w-full flex justify-center py-2 px-4 border mt-12 font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition">
          Go to Login
        </a>
      </div>
    </div>
  );
}

export default App;
