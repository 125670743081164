import React from 'react'

const ValCodes = ({ codes }) => {
  return(
    <div className='p-12 bg-secondary rounded-xl mb-20'>
      <h4 className='mb-4'>Current Codes:</h4>
      {(codes.length > 0) ? (
        <ul id='codes' className={`w-full`}>
          <li className="flex justify-between items-center">
            <span className='font-bold w-1/3'>Code</span>
            <span className='font-bold w-1/3 text-center'>Status</span>
            <span className='font-bold w-1/3 text-right'>Created</span>
          </li>

          {codes.map((code, index) => (
            <li key={index} className="mb-2 flex justify-between items-center">
              <span className='w-1/3'>{code.code}</span>
              <span className='w-1/3 text-center'>{(code.active == 1) ? 'Active' : 'Inactive'}</span>
              <span className='w-1/3 text-right'>{code.created}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p className='text-center font-medium'>No active lots found. <br></br>You can start adding some with the button above.</p>
      )}
    </div>
  )
}

export default ValCodes